* {
  margin: 0;
}

body {
  background-color: #f5f6fa;
}

.app {
  display: flex;
  justify-content: space-evenly;
  background-color: #f5f6fa;
  padding: 20px;
}

.app__dropdown {
  background-color: white;
}

.app__header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
}

.app__header > h1 {
  color: #fc3c3c;
  font-size: 2rem;
}

.app__stats {
  display: flex;
  justify-content: space-between;
}

.app__left {
  flex: 0.9;
}

.app__information > h3 {
  color: #6a5d5d;
  font-weight: 400;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.app__information > h3:last-of-type {
  margin-top: 1rem;
}

@media (max-width: 990px) {
  .app {
    flex-direction: column;
  }
}
